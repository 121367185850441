.terminal__contents {
    display: flex;
    color: whitesmoke;
    padding: 10px;
    flex-direction: column;
}

.terminal__commandArea{
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.terminal__hostname {
    color: teal;
    font-size: 1rem;
}
.terminal__input {
    width: 100%;
    background: #1E1E1E;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    margin-left: 5px;
    color: whitesmoke;
    caret-color: gray;
}
.disable__input {
    pointer-events: none;
}

.results {
    margin-bottom: 20px;
}


@media screen and (min-width: 800px) {
    .terminal__hostname, .terminal__input {
        font-size: 1rem;
    }
}

@media screen and (max-width: 800px) {
    .terminal__hostname, .terminal__input {
        font-size: 0.9rem;
    }
    .results {
        font-size: 0.8rem;
    }
    
}

@media screen and (max-width: 650px) {
    .terminal__hostname, .terminal__input {
        font-size: 0.8rem;
    }
    .results {
        font-size: 0.6rem;
    }
}

@media screen and (max-width: 400px) {
    .terminal__hostname, .terminal__input {
        font-size: 0.7rem;
    }
    .results {
        font-size: 0.5rem;
    }
    
}