.header {
    padding: 0 0 50px 0;
    display: flex;
    width: 100vh;
    justify-content: center;
}

.icon {
    padding: 0 20px 0 20px;
    width: 30px !important;
    height: 50px !important;
    color: blanchedalmond;
    cursor: pointer;
    transition: all 0.2s ease-in !important;
}

.icon:hover {
    color: skyblue;
}


@media screen and (max-width: 800px) {
    
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {
    
}