.terminal {
    display: flex;
    background-color: #ededed;
    height: 70vh;
    width: 70vw;
    /* box-shadow: -1px 5px 20px -6px rgba(0,0,0,0.7); */
    box-shadow: 7px 5px 27px #121212, -7px -5px 27px #111;
    border-radius: 10px 10px 20px 20px;
    flex-direction: column;
    transition: all 0.3s ease-in;
}

.content__elements {
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 70%;
}

.content__elements > span {
    width: 70%;
}

.terminal__bar {
    width: 100%;
    height: 30px;
    background-color: #4D4D4D;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

}
.terminal__cancleBtn{
    color: #111;
    margin-right: 10px;
}

.terminal__body {
    background: #1E1E1E;
    width: 100%;
    height: 100%;
    border-radius: 0 0 16px 16px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
}

.linktree__list {
    list-style: none;
}

.linktree__list_a, .cv__a {
    color: whitesmoke;
    text-decoration: none;
    text-align: center;
    vertical-align: center;
    transition: all 0.3s ease-in;
}

.linktree__list_a:hover, .cv__a:hover {
    color: gray;
}

.cv__a {
    text-decoration: underline;
}

@media screen and (min-width: 800px) {
    .content__elements {
        font-size: 1rem;
    }
    .terminal {
        width: 100vh;
    }
}

@media screen and (max-width: 800px) {
    .content__elements {
        font-size: 0.80rem;
    }
    .terminal {
        width: 80vh;
    }
    .terminal__bar {
        height: 25px;
    }
    .terminal__cancleBtn{
        height: 20px !important;
        width: 20px !important;
    }
}

@media screen and (max-width: 650px) {
    
    .content__elements {
        font-size: 0.6rem;
    }
    .terminal {
        width: 50vh;
    }
    .terminal__bar {
        height: 20px;
    }
    .terminal__cancleBtn{
        height: 15px !important;
        width: 15px !important;
    }
}

@media screen and (max-width: 400px) {

    .content__elements {
        font-size: 0.5rem;
    }
    .terminal {
        width: 45vh;
    }
    .terminal__bar {
        height: 15px;
    }
    .terminal__cancleBtn{
        height: 10px !important;
        width: 10px !important;
    }
}